.f-footer {
	background: $text;
	color: $white;
	padding-block: inEm(30px);

	@include sm-down {
		padding: inEm(40px) inEm(32px);
	}

	&__container {

		@include sm-down {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__side {

		&_left {

			@include sm-down {
				margin-bottom: inEm(32px);
			}
		}
	}

	&__nav {
		column-gap: inEm(40px);

		@include sm-down {
			flex-direction: column;
			row-gap: inEm(16px);
		}
	}

	&__nav-link {
		position: relative;
		font-size: inEm(16px);
		line-height: inEm(20px, 16px);
		padding-block: inEm(3px, 16px);
		font-weight: 500;
		width: max-content;

		&_active {
			pointer-events: none;

			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 1px;
				background-color: $white;
			}
		}
	}

	&__copyright {
		font-size: max(13px, inEm(13px));
		line-height: inEm(16px, 13px);
		color: $copyright;
	}
}
