.f-header {
	color: $accent;
	padding-top: inEm(32px);

	@include md-down {
		padding-top: inEm(16px);
	}

	@include sm-down {
		padding-top: inEm(24px);

		&_menu & {

			&__logo-container {

				svg {

					path {
						fill: $white;
					}
				}
			}

			&__nav-container {
				display: flex;
			}
		}
	}

	&__logo-container {
		display: block;
		width: inEm(118px);
		height: inEm(24px);
	}

	&__side {

		&_left {
			column-gap: inEm(40px);
		}

		&_right {

			a {

				@include sm-down {
					display: none;
				}
			}
		}
	}

	&__nav-container {

		@include sm-down {
			position: fixed;
			z-index: 6;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			background: $blue;
			padding: inEm(190px) inEm(32px) inEm(40px);
			color: $white;
			flex-direction: column;
			display: none;
			overflow-x: hidden;
		}
	}

	&__nav {
		column-gap: inEm(40px);

		@include sm-down {
			text-align: center;
			flex-direction: column;
			align-items: center;
			row-gap: inEm(41px);
		}
	}

	&__nav-link {
		position: relative;
		font-size: inEm(16px);
		line-height: inEm(20px, 16px);
		padding-block: inEm(3px, 16px);
		font-weight: 500;

		@include sm-down {
			font-size: inEm(24px);
			line-height: inEm(28px, 24px);
			padding-block: inEm(5px, 24px);
			font-weight: 800;
		}

		&_active {
			pointer-events: none;

			@include sm-down {
				text-decoration: underline;
			}

			&::after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				height: 1px;
				background-color: $accent;

				@include sm-down {
					content: none;
				}
			}
		}

		&_mobile {

			@include sm-up {
				display: none;
			}
		}
	}

	&__mobile-header {

		.f-header__logo-container {
			position: absolute;
			top: inEm(24px);
			left: inEm(16px);
		}
	}

	&__mobile-open {
		width: inEm(24px);
		height: inEm(24px);

		svg {
			pointer-events: none;
		}
	}

	&__mobile-close {
		width: inEm(24px);
		height: inEm(24px);
		top: inEm(24px);
		right: inEm(16px);

		svg {
			pointer-events: none;
		}
	}

	&__nav-container-mobile {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
		row-gap: inEm(40px);
		min-height: inEm(250px);

		.f-qr__items {
			justify-content: center;
		}
	}

	&__mobile-open,
	&__mobile-header,
	&__nav-container-mobile {

		@include sm-up {
			display: none;
		}
	}
}
