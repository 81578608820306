.f-error-page {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__title {
		font-size: 10vw;
		font-weight: 800;
		color: $text;

		@include md-down {
			font-size: 15vw;
		}

		@include sm-down {
			font-size: 30vw;
		}
	}
}
