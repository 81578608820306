.f-button {
	$root: &;

	display: inline-flex;
	align-items: center;
	padding: inEm(16px) inEm(32px);
	border: 1px solid $accent;
	border-radius: inEm(100px);
	background: $white;
	transition: background $transition-duration;

	@include sm-down {
		width: 100%;
		justify-content: center;
	}

	@include mouseover {
		background: $button-hover;
	}

	&_dark {
		background: $accent;

		@include mouseover {
			background: #4d6b95;
		}
	}
	&_dark & {

		&__title {
			color: $white;
		}
	}

	svg {
		width: inEm(24px);
		height: inEm(24px);
		margin-right: inEm(12px);
	}

	&__title {
		pointer-events: none;
		font-size: inEm(16px);
		line-height: inEm(24px, 16px);
		font-weight: 500;
		color: $accent;
	}
}
