.f-business-features {
	position: relative;
	z-index: 1;
	margin-top: inEm(120px);

	@include sm-down {
		margin-top: inEm(48px);
	}

	&__items {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: inEm(20px);

		@include md-down {
			grid-template-columns: 1fr;
			row-gap: inEm(16px);
		}
	}
}

.f-business-feature {
	$root: &;

	background: $white;
	color: $text;
	padding: inEm(32px);
	border-radius: inEm(40px);

	@include md-down {
		column-gap: inEm(40px);
		padding: inEm(40px);
	}

	@include sm-down {
		flex-direction: column;
		padding: inEm(24px);
		border-radius: inEm(20px);
		row-gap: inEm(24px);
	}

	&:nth-child(1) {

		@include md-up {
			grid-row: 1 / 3;
			flex-direction: column;
			justify-content: flex-end;

			#{$root} {

				&__text-container {
					order: 1;
					max-width: inEm(450px);
					margin-top: inEm(32px);
				}

				&__image-container {
					width: inEm(300px);
					height: inEm(220px);
				}
			}
		}
	}

	&__text-container {
		max-width: inEm(320px);

		@include md-down {
			max-width: none;
			order: 1;
		}
	}

	&__title-container {

		@include md-down {
			max-width: inEm(458px);
		}
	}

	&__title {
		font-size: inEm(24px);
		line-height: inEm(26px, 24px);
		letter-spacing: inEm(-0.42px, 24px);
		font-weight: 700;

		@include sm-down {
			font-size: inEm(18px);
		}
	}

	&__description-container {
		margin-top: inEm(16px);
	}

	&__image-container {
		flex-shrink: 0;
		width: inEm(212px);
		height: inEm(156px);

		@include md-down {
			width: inEm(142px);
			height: inEm(104px);
		}

		@include sm-down {
			width: inEm(87px);
			height: inEm(64px);
		}
	}
}
