@font-face {
	font-family: MPLUS1p;
	font-display: swap;
	src: url("../../fonts/MPLUS1p-ExtraBold.woff2") format("woff2"), url("../../fonts/MPLUS1p-ExtraBold.woff") format("woff");
	font-weight: 800;
	font-style: normal;
}
@font-face {
	font-family: MPLUS1p;
	font-display: swap;
	src: url("../../fonts/MPLUS1p-Bold.woff2") format("woff2"), url("../../fonts/MPLUS1p-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: MPLUS1p;
	font-display: swap;
	src: url("../../fonts/MPLUS1p-Regular.woff2") format("woff2"), url("../../fonts/MPLUS1p-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: MPLUS1p;
	font-display: swap;
	src: url("../../fonts/MPLUS1p-Medium.woff2") format("woff2"), url("../../fonts/MPLUS1p-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}
