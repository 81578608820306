.f-terms {
	padding-top: inEm(100px);
	padding-bottom: inEm(48px);

	@include md-down {
		padding-top: inEm(80px);
		padding-bottom: inEm(88px);
	}

	@include sm-down {
		padding-top: inEm(24px);
		padding-bottom: inEm(48px);
	}

	&__inner {
		max-width: inEm(1097px);
	}

	&__title-container {
		margin-bottom: inEm(48px);

		@include md-down {
			margin-bottom: inEm(64px);
		}

		@include sm-down {
			margin-bottom: inEm(24px);
		}
	}

	&__title {
		color: $text;
	}

	&__content {
		color: $content;
		font-size: inEm(16px);
		line-height: inEm(24px, 16px);

		@include sm-down {
			font-size: inEm(15px);
			line-height: inEm(22px, 15px);
		}

		& > * {
			font-weight: 500;

			&:not(:last-child) {
				margin-bottom: inEm(24px, 16px);

				@include sm-down {
					margin-bottom: inEm(22px, 15px);
				}
			}
		}

		a {
			text-decoration: underline;
		}

		h2 {
			text-transform: uppercase;

			&:not(:first-child) {
				margin-top: inEm(48px, 16px);

				@include sm-down {
					margin-top: inEm(44px, 15px);
				}
			}
		}
	}
}
