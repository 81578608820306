@use 'sass:selector';

@import 'vars';
@import 'media-queries';

// Theme settings for editor and frontend
@mixin website_style_guide {
	// Colors
	--color-bg: #{$white};
	--color-text: #{$text};
	--color-primary: #{$blue};

	background: var(--color-bg);
	color: var(--color-text);
	font-family: $font-family-primary;
	font-size: $base-font-size;
	line-height: 1.25;

	@media (min-width: $responsive-xl) {
		font-size: $calc-font-size;
	}
	@include lg-down {
		font-size: $base-font-size-lg;
	}
	@include md-down {
		font-size: $calc-font-size;
	}
}

@mixin unify-parent($child) {

  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

@mixin mouseover {

	@media (hover: hover) {

		&:hover {
			@content;
		}
	}
}

@mixin hover {
	cursor: pointer;
	transition: opacity $transition-duration;

	@include mouseover {
		opacity: 0.7;
	}
}

@mixin ellipse($width) {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: $width;
}

@mixin rows-limit($rows) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin loader {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	width: 48px;
	height: 48px;
	border: 5px solid $white;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}
@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@mixin border-text {
	color: transparent;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: rgba($white, 0.5);
}
