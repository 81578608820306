@import '../helpers';

html {
	@include website_style_guide();

	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	scroll-behavior: smooth;
}

body {
	word-break: break-word;
	scroll-behavior: smooth;
}

.f-site-content {
	display: flex;
	flex-direction: column;
	max-width: 100vw;
	min-height: 100dvh;
	min-width: 320px;
	overflow-x: hidden;
	background: var(--color-bg);

	&_dark {
		background: $blue;
		color: $white;

		.f-header {
			color: $white;
		}

		.f-header__logo-container {

			svg {

				path {
					fill: $white;
				}
			}
		}

		.f-header__nav-link_active {

			&::after {
				background-color: $white;
			}
		}

		.f-header__mobile-open {

			svg {

				rect {

					fill: $white;
				}
			}
		}
	}
}

main {
	overflow: hidden;
	flex-grow: 1;
	width: 100%;
}

.f-outer {
	width: 100%;
	padding-inline: $base-outer-padding-desktop;

	@include md-down {
		padding-inline: inEm(32px);
	}

	@include sm-down {
		padding-inline: $base-outer-padding-mobile;
	}
}

.f-wrapper {
	width: 100%;
	max-width: calc($base-container-width - $base-outer-padding-desktop * 2);
	margin-inline: auto;
}
