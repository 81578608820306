*,
:before,
:after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

body {
	position: relative;
	background-color: #fff;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
	display: block;
}

table {
	border-collapse: collapse;
	border-width: 0;
	padding: 0;
	margin: 0;
}

input,
textarea {
	color: inherit;
	border-radius: 0;
	-webkit-appearance: none;
}

input[type="button"],
input[type="submit"],
button {
	cursor: pointer;
	-webkit-appearance: none;
}

td {
	padding: 0;
	margin: 0;
}

form {
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

img {
	height: auto;
	max-width: 100%;
}

ul,
ol {
	list-style-type: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
hr,
blockquote {
	margin: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: normal;
}

hr {
	border: none;
}

figure {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
}

a,
button,
input,
textarea {
	outline: none;
}

a {
	text-decoration: none;
}

pre {
	margin: 0;
}

ul {
	margin: 0;
	padding: 0;
	padding-inline-start: 0;
	list-style: none;
}

ol {
	padding-inline-start: 0;
}

img {
	aspect-ratio: auto;
}

input,
textarea {
	border: none;
	border-radius: 0;
}

button {
	padding: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: 0;
	background: transparent;
	border: none;
	cursor: pointer;
}
