.f-qr {

	&_header {

		@include md-down {
			display: none;
		}
	}

	&__items {
		column-gap: inEm(40px);

		@include sm-down {
			gap: inEm(8px);
		}
	}

	&__item {
		width: inEm(152px);
	}

	&__code-container {
		width: 100%;
		aspect-ratio: 1/1;
		margin-bottom: inEm(10px);

		@include sm-down {
			display: none;
		}
	}

	&__store-container {
		width: 100%;
		height: inEm(44px);
	}
}
