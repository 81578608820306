.f-business {
	position: relative;
	padding-bottom: inEm(72px);

	@include md-down {
		padding-bottom: inEm(80px);
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: inEm(-58px);
		height: inEm(800px);
		background-repeat: no-repeat;
		background-size: contain;
	}

	&::before {
		left: 0;
		width: inEm(379px);
		height: inEm(846px);
		background-image: url($img-path + '/dec-business-left.svg');

		@include md-down {
			left: inEm(-33px);
			top: inEm(30px);
		}
	}

	&::after {
		right: 0;
		width: inEm(745px);
		background-image: url($img-path + '/dec-business-right.svg');

		@include md-down {
			right: inEm(-33px);
			top: inEm(32px);
		}
	}

	&__wrapper {
		position: relative;
		z-index: 2;
	}
}
